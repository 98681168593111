@import '../../styles/theme.scss';
.avatar {
  background-color: #9093ab;
}

.updateProfileForm {
  :global {
    .ant-form-item-row {
      display: block;
    }
    .ant-input-disabled {
      color: grey !important;
    }
    .ant-form-item-control-input-content {
      display: flex;
      align-items: center;
    }
  }

  .avatarContainer {
    display: flex;
    margin-bottom: 25px;
    align-items: center;

    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: center;
    }

    .userInfoContainer {
      min-width: 80%;
      margin: auto;
      padding-left: 15px;

      @media (max-width: 768px) {
        width: 100%;
        padding-left: 0;
      }
    }
  }

  .formItem {
    padding: 0;
    margin: auto 15px;
  }

  div {
    padding: 0;
    margin: 0;
  }

  .inputField {
    font-size: $font-size-regular;
    background-color: $background-color-input;
    line-height: 2;
    border: none;

    input {
      padding: 5px;
      width: 100%;
    }

    input,
    div,
    span,
    div {
      background-color: $background-color-input;

      &:focus,
      &:active,
      &::content {
        background-color: #fff;
      }
    }
  }

  .submitBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: larger;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 20px;
    background-color: $background-color-button-primary;
  }
}
