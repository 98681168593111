@import '../../styles/theme.scss';

.resetPasswordContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: $text-color-primary;
  width: 100%;
  padding: 30px;

  :global {
    .ant-form-item-explain-error {
      max-width: 435px;

      @media (max-width: 1024px) {
        max-width: 300px;
      }
    }
  }

  .textContainer {
    text-align: center;

    .title {
      font-size: $font-size-large !important;
      margin: 0;
    }

    .text {
      font-size: $font-size-regular;
      color: $text-color-secondary;
      margin: 0 0 15px 0;
    }
  }

  .resetPasswordForm {
    min-width: 40%;

    .formItem {
      padding: 0;
      margin: 20px 0;
    }

    div {
      padding: 0;
      margin: 0;
    }

    .inputField {
      font-size: $font-size-regular;
      background-color: $background-color-input;
      line-height: 2;
      width: 435px;
      border: none;

      input {
        padding: 10px;
      }

      input,
      div,
      span,
      div {
        background-color: $background-color-input;

        &:focus,
        &:active,
        &::content {
          background-color: #fff;
        }
      }

      @media (max-width: 1024px) {
        max-width: 300px;
      }
    }

    .resetPasswordBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
      width: 100%;
      margin: 10px auto;
      background-color: $background-color-button-primary;
    }

    .backBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
      width: 100%;
      margin: 10px auto;
      color: $text-color-primary;
      border: 1.5px solid $border-color;
      background-color: $background-color-button-secondary;
      box-shadow: none;
    }

    .loginText {
      text-align: center;
      color: $text-color-secondary;
    }
  }
}

.feedback {
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
