@import '../../styles/theme.scss';

.resetPasswordContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: $text-color-primary;
  width: 100%;
  padding: 30px;

  :global {
    .ant-form-item-explain-error {
      max-width: 435px;

      @media (max-width: 1024px) {
        max-width: 300px;
      }
    }
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 5px;
    text-align: center;

    .title {
      font-size: $font-size-large !important;
      margin: 0;
    }

    .text {
      font-size: $font-size-regular;
      color: $text-color-secondary;
      margin: 0 0 15px 0;
    }

    .action {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
      width: 50%;
      background-color: #6c63ff;
    }
  }
}