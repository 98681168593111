@import '../../styles/theme.scss';

:global {
  .ant-menu-item {
    display: flex;
    align-items: center;
    font-size: 16px;
    justify-content: center;
    padding: 5px;
    margin: 5px 15px;
    margin-bottom: 10px;
    border: none;
    color: #585858;
    height: auto !important;
    border-radius: 20px !important;
  }

  .ant-menu-item-selected {
    background-color: #e7e7e75d !important;
    color: black !important;
  }
}

.wrapper {
  display: flex;
  background-color: #fafafa;
  min-height: 100vh;

  .menuContainer {
    display: flex;
    flex-direction: column;
    width: 300px;

    @media screen and (max-width: 1024px) {
      width: 100px;
    }

    .title {
      background-color: white;
      padding: 25px 0 15px 0;
      width: 100%;
      margin: 0;
      font-size: larger;
      color: rgb(73, 73, 73);
      text-align: center;
      border-inline-end: 1px solid rgba(5, 5, 5, 0.06);
    }

    .menu {
      padding: 10px;
      width: 300px;
      height: 100%;
      
      @media screen and (max-width: 1024px) {
        width: 100px;
      }
    }
  }

  .accountInfoContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 850px;
    margin: 100px auto;

    @media (max-width: 896px) {
      margin: 100px 20px;
    }

    .formContainer {
      background-color: white;
      border: 1px solid #e9eef3;
      border-radius: 15px;
      padding: 15px 30px;
      margin: 15px;
    }
  }
}