@import '../../styles/theme.scss';

.updatePasswordForm {
  :global {
    .ant-form-item-row {
      display: block;
    }
    .ant-form-item-control-input-content {
      display: flex;
      align-items: center;
    }
  }

  .formItem {
    padding: 0;
    margin: auto 15px;
  }

  div {
    padding: 0;
    margin: 0;
  }

  .inputField {
    font-size: $font-size-regular;
    background-color: $background-color-input;
    line-height: 2;
    border: none;

    input {
      padding: 10px;
      width: 100%;
    }

    input,
    div,
    span,
    div {
      background-color: $background-color-input;

      &:focus,
      &:active,
      &::content {
        background-color: #fff;
      }
    }
  }

  .submitBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 20px;
    font-size: larger;
    background-color: $background-color-button-primary;
  }
}
