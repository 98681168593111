$font-family-primary: 'Nunito Sans', sans-serif;
$background-color-body: #f2f3f7;
$background-color-input: #f3f5f9;
$background-color-div: #ffffff;
$background-color-hover: #e7e7e74d;
$text-color-primary: #2d313d;
$text-color-secondary: #8190a0;
$background-color-button-primary: #6c63ff;
$background-color-button-secondary: #ffffff;
$text-color-button-primary: #ffffff;
$text-color-button-secondary: #6c63ff;
$font-size-large: 34px;
$font-size-regular: 16px;
$font-size-small: 12px;
$font-weight-bold: 700;
$font-weight-light: 400;
$border-width: 0.125rem;
$border-color: #e9eef3;
$border-radius-input: 0.3rem;
$border-radius-button: 0.5rem;
$error-color: #f5222d;
$warning-color: #faad14;
$link-color: #1890ff;
$outline-primary-color: #6c63ff;