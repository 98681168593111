@import '../../styles/theme.scss';

.modal {
  * {
    list-style-type: decimal;
  }

  :global {
    .ant-modal-title {
      font-size: x-large;
      text-align: center;
      margin: 20px;
      color: $text-color-button-secondary;
    }

    .ant-btn-primary {
      background-color: $background-color-button-primary;
      width: 100px;
    }

    .ant-btn {
      width: 100px;
      margin: 20px 10px;
    }
  }

  ol {
    text-align: justify;
    padding-right: 20px;
  }

  .listItemTitle {
    color: #2d313dc9;
    font-size: 17px;
    margin: 0;

    h3 {
      margin: 0;
      font-size: 16px;
    }
    
    p {
      font-size: 14px;
    }
  }

  .listItemText {
    font-size: 16px;
    color: $text-color-secondary;
    margin: 5px auto 20px auto;
  }
}
